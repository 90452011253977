<template>
  <TheWrapper>
    <form
      novalidate="novalidate"
      class="wrapper__container"
      @submit.prevent="next"
    >
      <h2 class="step__main-title">
        {{ $t('How many parcels are you sending back?') }}
      </h2>
      <p class="step__paragraph">
        {{ $t('An even amount of shipping labels will be generated. Stick one label on each parcel.') }}
      </p>
      <UiNumberStepper
        class="number-stepper-override"
        :decrease-text="$t('Remove an item')"
        :increase-text="$t('Add an item')"
        :hide-label="true"
        :max-value="parcel.collo_count"
        :min-value="1"
        :value="selectedValue"
        :label="$t('How many parcels are you sending back?')"
        name="return_parcels_number"
        type="number"
        @input="updateParcelsNumber"
      />
      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          class="main-button__btn"
          data-test="main-button"
          :style="brandStyle"
        >
          <span>{{ $t('Continue') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>
<script>
import { handleRouteRejection } from '@/utils'
import { brandColourMixin } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { mapState } from 'vuex'
import { UiButton, UiNumberStepper } from '@sendcloud/ui-library'
import { APP_SET_PARCELS_NUMBER } from '@/store/mutation.types'
import TheWrapper from '@/components/layout/TheWrapper'
export default {
  name: 'ParcelsNumberSelectionView',
  faChevronRight,
  components: {
    FontAwesomeIcon,
    UiButton,
    UiNumberStepper,
    TheWrapper,
  },

  mixins: [brandColourMixin],

  data() {
    return {
      value: 1,
    }
  },

  computed: {
    ...mapState({
      parcel: (state) => state.parcel,
      parcelsNumber: (state) => state.parcelsNumber,
    }),

    selectedValue() {
      return this.parcelsNumber ? this.parcelsNumber : this.value
    },
  },

  methods: {
    next() {
      const packingItems = this.$store.state.packingItems

      const firstItem = packingItems.length > 0 && packingItems[0].id !== undefined ? packingItems[0].id : null
      let location = { name: 'return-reasons', params: { item: firstItem } }

      if (packingItems.length >= 2) {
        location = { name: 'items-selection' }
      }

      this.$router.push(location).catch(handleRouteRejection)
    },

    updateParcelsNumber(newValue) {
      this.$store.commit(APP_SET_PARCELS_NUMBER, newValue)
    },
  },
}
</script>
<style lang="scss" scoped>
.number-stepper-override {
  max-width: 19.6875rem;
  margin: auto;
  width: 100%;
}

.step__paragraph {
  margin: 0 auto 2.875rem;
}

.wrapper__container {
  text-align: center;
}
</style>
